.card-color-clubs {
    color: black;
}
.card-color-clubs img {
    filter: brightness(0) saturate(100%);
}
.card-color-hearts {
    color: red;
}
.card-color-hearts img {
    filter: brightness(0) saturate(100%)
            invert(21%) sepia(62%) saturate(6444%) hue-rotate(354deg) brightness(99%) contrast(125%);
}
.card-color-spades {
    color: #007000;
}
.card-color-spades img {
    filter: brightness(0) saturate(100%)
            invert(24%) sepia(91%) saturate(1124%) hue-rotate(89deg) brightness(102%) contrast(106%);
}
.card-color-diamonds {
    color: orange;
}
.card-color-diamonds img {
    filter: brightness(0) saturate(100%)
            invert(77%) sepia(65%) saturate(3873%) hue-rotate(360deg) brightness(102%) contrast(101%);
}

.card {
    display: inline-block;
    position: relative;

    width: 6em;
    height: 9.25em;
    border-radius: 0.3em;
    border: 0.07em solid black;
    background-color: white;

    font-family: "Courier New, serif";

    font-size: 1.2em;

    user-select: none;
}

.card * {
    cursor: default;
    pointer-events: none;
}

.card .position-top {
    display: inline-block;
    position: absolute;
    width:  90%;
    height: 98%;
    top:  1%;
    left: 5%;
}

.card .position-bottom {
    display: inline-block;
    transform: translate(-100%, -100%) rotate(180deg);
    position: absolute;
    width:  90%;
    height: 98%;
    top:  99%;
    left: 95%;
}

.card .position-corner {
    display: inline-block;
    position: absolute;
    left: 0%;
    top:  0%;
}

.card .position-symbol-corner {
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 130%;
}

.card .position-symbol-0 {
    position: absolute;
    left: 30%;
    top: 15%;

}
.card .position-symbol-1 {
    position: absolute;
    left: 70%;
    top: 15%;
}
.card .position-symbol-2 {
    position: absolute;
    left: 30%;
    top: 50%;
}
.card .position-symbol-3 {
    position: absolute;
    left: 50%;
    top: 32.5%;
}
.card .position-symbol-4 {
    position: absolute;
    left: 70%;
    top: 50%;
}

.card .position-symbol-5 {
    position: absolute;
    left: 30%;
    top: 35%;
}
.card .position-symbol-6 {
    position: absolute;
    left: 50%;
    top: 50%;
}
.card .position-symbol-7 {
    position: absolute;
    left: 70%;
    top: 35%;
}
.card .position-symbol-8 {
    position: absolute;
    left: 50%;
    top: 25%;
}

.card .position-center {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
}

.card .position-center img {
    display: inline-block;
    position: absolute;
    left: 0%;
    top:  0%;
}
